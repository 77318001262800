import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from '@mona/api';
import { Encounter } from '@mona/models';
import { AuditEvent, Bundle } from '../../../../shared/src/lib/types/r4b.type';

const baseApi = `/pdms/fhir/AuditEvent`;

/**
 * API abstraction layer for the Audit Log API
 */
@Injectable({
    providedIn: 'root',
})
export class AuditLogApi {
    /**
     * Constructor
     *
     * @param http HttpService
     */
    constructor(private http: HttpService) {}

    /**
     * Get audit logs for an encounter
     *
     * @param encounter
     */
    getAuditLogs(encounter: Encounter): Observable<AuditEvent[]> {
        const date = [`ge${encounter.startDate.toISOString()}`];
        if (encounter.endDate) {
            date.push(`le${encounter.endDate.toISOString()}`);
        }
        return this.http
            .get<Bundle<AuditEvent>>(baseApi, {
                params: {
                    'entity-name': `Patient/${encounter.patientId}`,
                    _sort: '-date',
                    date,
                },
            })
            .pipe(
                map(bundle => {
                    if (bundle.resourceType !== 'Bundle') {
                        throw new Error('Invalid FHIR Bundle');
                    }
                    return bundle.entry
                        .map(entry => entry.resource as AuditEvent)
                        .filter(resource => resource.resourceType === 'AuditEvent');
                }),
            );
    }
}
