import { Injectable } from '@angular/core';
import {
    Actions,
    concatLatestFrom,
    createEffect,
    EffectNotification,
    ofType,
    OnInitEffects,
    OnRunEffects,
} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import { AuthService } from '@mona/auth';
import { withCurrentEncounterId } from '@mona/pdms/data-access-combined';
import { makeDefaultAsyncActionEffect } from '@mona/store';
import { NotificationsState } from '../../entities';
import { NotificationsApi, NotificationsWsService } from '../../infrastructure';
import { NotificationsAction } from '../actions/notifications.actions';
import * as NotificationsSelectors from '../selectors/notifications.selectors';

/**
 * Notifications effects
 */
@Injectable()
export class NotificationsEffects {
    initAction$: Observable<Action> = createEffect(
        () =>
            this.actions$.pipe(
                ofType(NotificationsAction.initNotificationSub),
                withCurrentEncounterId(),
                map(([, encounterId]) => NotificationsAction.loadNotificationsAction.action({ encounterId })),
            ),
        // { dispatch: false },
    );

    /**
     * Load notifications effect
     */

    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsAction.loadNotificationsAction.action),
            switchMap(action =>
                makeDefaultAsyncActionEffect(
                    this.notificationsApi.getNotifications(action.encounterId),
                    NotificationsAction.loadNotificationsAction,
                ),
            ),
        ),
    );

    /**
     * Resolve notification effect
     */

    resolveNotification$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsAction.resolveNotificationAction.action),
            concatLatestFrom(() => this.authService.authenticate()),
            switchMap(([action, { user }]) =>
                makeDefaultAsyncActionEffect(
                    this.notificationsApi.resolveNotification(action.notification.id, action.resolution).pipe(
                        map(() => ({
                            ...action.notification,
                            resolution: action.resolution,
                        })),
                    ),
                    NotificationsAction.resolveNotificationAction,
                ),
            ),
        ),
    );

    /**
     * Resolve notification succeeded effect
     */

    resolveNotificationSucceeded$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsAction.resolveNotificationAction.succeededAction),
            switchMap(action =>
                this.store.select(NotificationsSelectors.selectNotifications).pipe(
                    take(1),
                    switchMap(currentNotifications => {
                        return [
                            NotificationsAction.loadNotificationsAction.succeededAction({
                                payload: currentNotifications.filter(item => item.id !== action.payload.id),
                            }),
                        ];
                    }),
                ),
            ),
        ),
    );

    /**
     * Change read status effect
     */
    changeReadStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationsAction.changeReadStatusAction.action),
            switchMap(action => {
                const { notificationIds, readStatus } = action;

                return from(notificationIds).pipe(
                    mergeMap(id =>
                        this.notificationsApi.changeNotificationReadStatus(id, readStatus).pipe(
                            map(notification =>
                                NotificationsAction.changeReadStatusAction.succeededAction({
                                    payload: notification,
                                }),
                            ),
                            catchError(error =>
                                of(
                                    NotificationsAction.changeReadStatusAction.failedAction({
                                        error,
                                    }),
                                ),
                            ),
                        ),
                    ),
                );
            }),
        ),
    );

    /**
     * Constructor
     *
     * @param store Store
     * @param actions$ Actions
     * @param notificationsApi NotificationsApi
     * @param notificationsWsService
     * @param authService AuthService
     */
    constructor(
        private store: Store<{ notifications: NotificationsState }>,
        private actions$: Actions,
        private notificationsApi: NotificationsApi,
        private notificationsWsService: NotificationsWsService,
        private authService: AuthService,
    ) {}

    /**
     * Handles encounter notifications
     *
     * @param encounterId EntityId<Encounter>
     */
    private handleNotifications(encounterId: string): void {
        this.notificationsWsService.disconnect();
    }

    /**
     * Reconnects ws after connection is reestablished
     * Had to reconnect after connection reestablished as after long offline status
     * existing ws reconnection logic does not work anymore
     */
    private handleConnectionReestablished(): void {
        //
    }
}
