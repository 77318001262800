import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { encounterDataFeatureKey } from './entities';
import {
    AllergiesEffects,
    AnamnesisEffects,
    BalanceEffects,
    CareReportsEffects,
    DiagnosesEffects,
    EncounterEffects,
    encounterFeatureReducer,
    ENCOUNTER_FEATURE_REDUCER,
    LabValuesEffects,
    PhysicalExaminationEffects,
    PreMedicationsEffects,
    VentilationEffects,
    VitalSignsEffects,
    InfectionStatusEffects,
    TherapyLimitationsEffects,
    SurgeryPrescriptionEffects,
    VaccinationsEffects,
    WoundStatusEffects,
    ValuablesEffects,
    AdditionalDevicesEffects,
} from './state';
import { AuditLogEffects } from './state/effects/audit-log.effects';

/**
 * DataAccessEncounterDataModule
 */
@NgModule({
    imports: [
        //
        StoreModule.forFeature(encounterDataFeatureKey, ENCOUNTER_FEATURE_REDUCER),
        EffectsModule.forFeature([
            EncounterEffects,
            AdditionalDevicesEffects,
            AllergiesEffects,
            InfectionStatusEffects,
            WoundStatusEffects,
            ValuablesEffects,
            TherapyLimitationsEffects,
            SurgeryPrescriptionEffects,
            VaccinationsEffects,
            AnamnesisEffects,
            DiagnosesEffects,
            PreMedicationsEffects,
            PhysicalExaminationEffects,
            VitalSignsEffects,
            LabValuesEffects,
            VentilationEffects,
            BalanceEffects,
            CareReportsEffects,
            AuditLogEffects,
        ]),
    ],
    providers: [{ provide: ENCOUNTER_FEATURE_REDUCER, useValue: encounterFeatureReducer }],
})
export class DataAccessEncounterDataModule {}
