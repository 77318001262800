import { createAction, props } from '@ngrx/store';
import { Encounter } from '@mona/models';
import { AuditEvent } from '../../../../../shared/src/lib/types/r4b.type';

/**
 * load audit log
 */
export const loadAuditLogs = createAction(
    '[AuditLog] Load Audit Logs',
    props<{ encounter: Encounter }>()
);
/**
 * load audit log Success
 */
export const loadAuditLogsSuccess = createAction(
    '[AuditLog] Load Audit Logs Success',
    props<{ auditLogs: AuditEvent[]}>()
);
/**
 * load audit log Failure
 */
export const loadAuditLogsFailure = createAction(
    '[AuditLog] Load Audit Logs Failure',
    props<{ error: any }>()
);
