import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AuditLogApi } from '../../infrastructure/audit-log.api';
import * as AuditLogActions from '../actions/audit.log.actions';

/**
 * AuditLogEffects : loadAuditLogs
 */
@Injectable()
export class AuditLogEffects {
    loadAuditLogs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuditLogActions.loadAuditLogs),
            mergeMap(({  encounter }) =>
                this.auditLogApi.getAuditLogs(encounter).pipe(
                    map(auditLogs => {
                        return AuditLogActions.loadAuditLogsSuccess({ auditLogs });
                    }),
                    catchError(error => {
                        return of(AuditLogActions.loadAuditLogsFailure({ error }));
                    }),
                ),
            ),
        ),
    );

    /**
     * Constructor
     * @param actions$
     * @param auditLogApi
     */
    constructor(private actions$: Actions, private auditLogApi: AuditLogApi) {}
}
